:root {
  /* Common styles */
  --color-white: #fff;
  --color-black: #000;
  --color-orange: var(--color-yellow);
  --color-yellow: #3a78ff;
  --color-gold: var(--color-yellow);
  --color-purple: #906de5;
  --color-red: #f9053e;
  --color-green: #9dd562;
  --color-blue: #039be5;
  --color-dark-green: #09d395;
  --color-dark-grey: #666771;

  --color-blue-hover: #265acc;

  /* Background colors */
  --color-bg-main: rgba(31, 32, 40, 1);
  --color-bg-primary: rgba(40, 41, 49, 1);
  --color-bg-active: rgba(56, 57, 68, 1);

  /* Typography colors */
  --color-text-main: #000;
  --color-text-secondary: #9597a1;
  --color-text-inactive: #383944;
  --color-text-red: #f9053e;
  --color-text-green: #9dd562;
  --color-text-blue: #2fe29b;
  --color-input: rgba(56, 57, 68, 1);
  --color-text-footer: #42434e;
  --color-border-main: #000;
  --color-primary: var(--color-yellow);
  --color-secondary: #000;
}

body {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: "Alliance", sans-serif;
  font-stretch: normal;
  font-style: normal;
  background-color: var(--color-bg-main);
  color: var(--color-text-main);
}

a,
span,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

i {
  font-style: normal;
}

.input-error {
  color: var(--color-red);
  font-size: 18px;
}

@font-face {
  font-family: "Alliance";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/AllianceNo1Light/font.woff2") format("woff2"),
    url("../fonts/AllianceNo1Light/font.woff") format("woff");
}
@font-face {
  font-family: "Alliance";
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/AllianceNo1LightItalic/font.woff2") format("woff2"),
    url("../fonts/AllianceNo1LightItalic/font.woff") format("woff");
}
@font-face {
  font-family: "Alliance";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/AllianceNo1Regular/font.woff2") format("woff2"),
    url("../fonts/AllianceNo1Regular/font.woff") format("woff");
}
@font-face {
  font-family: "Alliance";
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/AllianceNo1RegularItalic/font.woff2") format("woff2"),
    url("../fonts/AllianceNo1RegularItalic/font.woff") format("woff");
}
@font-face {
  font-family: "Alliance";
  font-weight: bold;
  font-style: normal;
  src: url("../fonts/AllianceNo1SemiBold/font.woff2") format("woff2"),
    url("../fonts/AllianceNo1SemiBold/font.woff") format("woff");
}
@font-face {
  font-family: "Alliance";
  font-weight: bold;
  font-style: italic;
  src: url("../fonts/AllianceNo1SemiBoldItalic/font.woff2") format("woff2"),
    url("../fonts/AllianceNo1SemiBoldItalic/font.woff") format("woff");
}
@font-face {
  font-family: "Alliance";
  font-weight: bolder;
  font-style: normal;
  src: url("../fonts/AllianceNo1Medium/font.woff2") format("woff2"),
    url("../fonts/AllianceNo1Medium/font.woff") format("woff");
}
@font-face {
  font-family: "Alliance";
  font-weight: bolder;
  font-style: italic;
  src: url("../fonts/AllianceNo1MediumItalic/font.woff2") format("woff2"),
    url("../fonts/AllianceNo1MediumItalic/font.woff") format("woff");
}

.toast-container {
  cursor: default;
}
